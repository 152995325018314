import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: '/api/v1/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  // http://192.168.1.139
  // https://internal-dashboard.twhost.com.tw
})

Vue.prototype.$http = axiosIns

export default axiosIns
