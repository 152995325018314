import { $themeConfig } from '@themeConfig'
import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
    verticalSidebar: [],
  },
  getters: {},
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
    },
    UPDATE_VERTICAL_SIDEBAR(state, val) {
      state.verticalSidebar = val
    },
  },
  actions: {
    // (重整)側欄
    resetSidebar(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/check', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
