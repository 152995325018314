import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'
import client from './routes'
import admin from './routes/admin'
import user from './routes/user'
import {
  isUserLoggedIn, logout, setUserAuthPower, // , chkToken, chkUserToken,
} from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'admin-home' } },
    ...client,
    ...admin,
    ...user,
    {
      path: '*',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
  ],
})

// init()

router.beforeEach((to, from, next) => {
  // console.log(to)
  const isLoggedIn = isUserLoggedIn()
  if (!isLoggedIn) {
    logout()
    if (to.name !== 'page-login') return next({ name: 'page-login' })
  }

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    return next({ name: 'admin-home' })
  }

  if (to.meta.isAdmin && isLoggedIn) {
    store.dispatch('api/getTokenCheck')
      .then(response => {
        setUserAuthPower(response.data.data)
      })
      .catch(() => {
        logout()
        next({ name: 'page-login' })
      })
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
