import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import VueCountryCode from 'vue-country-code'
import VueMeta from 'vue-meta'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/v-viwer'
import '@/libs/v-calendar'
import '@/libs/v-contextmenu'
import '@/libs/socket-client'
import '@/libs/vue-markdown-highlight'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueMeta)
Vue.use(VueDOMPurifyHTML)
Vue.use(VueCountryCode)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
