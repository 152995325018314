export default {
  // Endpoints
  loginEndpoint: '/admin/login',
  registerEndpoint: '/admin/register',
  refreshEndpoint: '/auth/refresh',
  logoutEndpoint: '/admin/logout',
  checkEndpoint: '/admin/check',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
