export default [
  {
    path: '/admin/login.html',
    name: 'page-login',
    component: () => import('@/views/login/Login.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/admin/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      // layout: 'full',
    },
  },
]
