import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    supportWebp: false,
    windowWidth: 0,
    shallShowOverlay: false,
    themeImages: {
      notFoundImg: require('@/assets/images/commen/notfound.png'),
      loadingImg: require('@/assets/images/commen/loading.png'),
      noImg: require('@/assets/images/commen/noImage.svg'),
      emptyImg: require('@/assets/images/commen/image.svg'),
      infoImg: require('@/assets/images/commen/notifyBlue.png'),
      fireImg: require('@/assets/images/commen/fire.png'),
    },
    userData: JSON.parse(localStorage.getItem('userData')) ? JSON.parse(localStorage.getItem('userData')) : '',
    titleTemplate: '曠世國際有限公司',
    isSocketInitialized: false,
    authPower: null,
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    currentDeviceType: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.lg) return 'computer'
      return 'mobile'
    },
    // currentAuthPower: () => {
    //   const { authPower } = this
    //   return authPower
    // },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    UPDATE_USERDATA_STATE(state, val) {
      state.userData = val
    },
    UPDATE_AUTHPOWER_STATE(state, val) {
      state.authPower = val
    },
    SET_SOCKET_INIT(state, val) {
      state.isSocketInitialized = val
    },
    CHECK_SUPPORT_WEBP(state, val) {
      state.supportWebp = val
    },
  },
  actions: {
    // (是否)socket function存在
    isSocketFunctionExist(state, name) {
      if (typeof window.socket !== 'undefined' && typeof window.socket[name] === 'function') return true
      return false
    },
  },
}
