import useAppConfig from '@core/app-config/useAppConfig'
import { logout } from '@/auth/utils'
import store from '@/store'

const { skin } = useAppConfig()

export const blankSocketData = () => {
  const blankTodo = {
    refreshRemarkList: () => {},
    refreshTodoInfo: () => {},
    refreshTodoList: () => {},
    createRemark: () => {},
  }

  const blankUsers = {
    refreshUsersList: () => {}, // 刷新User列表
    refreshUserOnlineStatus: (id, status) => {
      const { userData } = store.state.app
      if (userData && userData.id === id) {
        const onlinetatusType = {
          online: 'visible',
          busy: 'hidden',
        }
        if (onlinetatusType[status]) store.commit('app/UPDATE_WINDOW_VISIBILITY_STATE', onlinetatusType[status])
      }
    }, // 刷新在線狀態
  }

  return {
    blankTodo,
    blankUsers,
  }
}

// (更換)深淺主題色
const changeSkin = isDark => {
  skin.value = isDark ? 'dark' : 'semi-dark'
}

// (初始化)Socket
const initSocket = () => {
  store.commit('app/SET_SOCKET_INIT', true)
}

// (初始化)本地
export const initWebFunctions = () => {
  const {
    blankTodo,
    blankUsers,
  } = blankSocketData()

  window.web = {
    todo: { ...blankTodo },
    users: { ...blankUsers },
    user: {
      changeSkin, // 更改主题亮暗
      logout, // 登出
      initSocket, // socket重新连线
    },
  }
}

initWebFunctions()
