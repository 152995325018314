import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // (生成)圖形驗證
    getImgCaptcha(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/captcha', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (簡易查詢)分類列表
    getMetaList(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/meta/${resolveData.key}`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // --------------------------------------------------------------------------------------
    // (查詢)備註列表
    getRemarkList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/remark', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (創建)備註列表
    setRemarkCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/remark', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)備註
    setRemarkUpdateContent(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/remark/${resolveData.remark_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)備註顯示
    setRemarkUpdateDisplay(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/remark/${resolveData.remark_id}/display`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)一鍵清除
    setRemarkClearDisplay(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/remark/display', {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)備註
    setRemarkDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/remark/${resolveData.remark_id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // --------------------------------------------------------------------------------------
    // (充值)錢包
    setQuotaCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/quota', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // --------------------------------------------------------------------------------------
    // (檢查)
    getTokenCheck() {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/auth/check', { headers: { 'Access-Token': useJwt.getToken() } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (登出)
    logout() {
      return new Promise((resolve, reject) => {
        axios('/auth/logout', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
