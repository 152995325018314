export default [
  // -------------------------------會員列表----------------------------------
  {
    path: '/admin/profile',
    name: 'profile',
    component: () => import('@/views/profile/Profile.vue'),
    meta: {
      pageTitle: '個人資料',
      breadcrumb: [
        {
          text: '個人資料',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/admin/customer/:id',
  //   name: 'admin-customer-view',
  //   component: () => import('@/views/admin/customer/customer-view/CustomerView.vue'),
  //   meta: {
  //     isAdmin: true,
  //     pageTitle: '會員列表',
  //     breadcrumb: [
  //       {
  //         text: '會員列表',
  //         to: '/admin/customer',
  //       },
  //       {
  //         text: '會員詳情',
  //         active: true,
  //       },
  //     ],
  //     navActiveLink: 'admin-customer',
  //     belong: 'admin-customer',
  //   },
  // },
]
