import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // (查詢)信箱API列表
    getAPIMailList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/mailApi', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)信箱API
    setAPIMailCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/mailApi', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)信箱API
    setAPIMailUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/mailApi/${resolveData.mail_api_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)信箱API
    setAPIMailDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/mailApi/${resolveData.mail_api_id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
